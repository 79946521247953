import { render, staticRenderFns } from "./FunilPage.vue?vue&type=template&id=99264264&scoped=true&"
import script from "./FunilPage.vue?vue&type=script&lang=js&"
export * from "./FunilPage.vue?vue&type=script&lang=js&"
import style0 from "./FunilPage.vue?vue&type=style&index=0&id=99264264&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99264264",
  null
  
)

export default component.exports