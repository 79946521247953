<template>
    <div>
        <b-modal ref="modal_graficos_funil" size="xl" hide-footer title="Graficos">
            <div class="d-block text-center">
                <div id="caixa_chart_atendimentos">
                    <!--{ "nome": "Profissional", "texto": [ "PJ", "PF" ], "valor": [ 0, 0 ] }-->
                    <div class="row">
                        <div class="col-sm">
                            <div style=" max-width:250px;height:250px; display: inline-table;">
                                <GraficoRosca class="mx-auto" :label="dadosGraficoAtendimento.texto" :dados="dadosGraficoAtendimento.valor" :responsive="false" />
                            </div>
                        </div>
                        <div class="col-sm">
                            <div style=" max-width:250px;height:250px; display: inline-table;">
                                <GraficoRosca class="mx-auto" :label="dadosGraficoTabulacao.texto" :dados="dadosGraficoTabulacao.valor" :responsive="false" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <table>
                                <tr class="informacao_grafico">

                                    <td colspan="2">Quantidade de Atendimentos <span style="font-weight:bold">{{porcentagemTabulados.total}}</span></td>
                                    <td></td>
                                </tr>
                                <tr class="informacao_grafico">
                                    <td style="width:20%">{{porcentagemTabulados.porcentagem_s}}% </td>
                                    <td> <span style="font-weight:bold">[{{-(dadosGraficoAtendimento.valor[1]-porcentagemTabulados.total) }} Atendimentos S/tabulação]</span></td>
                                </tr>
                                <tr class="informacao_grafico">
                                    <td style="width:20%">{{porcentagemTabulados.porcentagem_c}}% </td>
                                    <td> <span style="font-weight:bold">[{{porcentagemTabulados.total_tabulados}} Atendimentos C/tabulação]</span></td>
                                </tr>
                            </table>

                        </div>
                        <div class="col-sm">
                            <table>
                                <tr class="informacao_grafico">
                                    <td colspan="2">Total de Atendimentos <span style="font-weight:bold">{{dadosGraficoTabulacao.valor.reduce((a, b) => a + b, 0)}}</span></td>
                                    <td></td>
                                </tr>
                                <tr v-for="item in valores_tab" class="informacao_grafico">
                                    <td style="width:20%">{{calculaProcentagem(item.qnt, dadosGraficoTabulacao.valor)}}%</td>
                                    <td><span style="font-weight:bold">[{{item.qnt}} {{item.nome}}]</span></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal ref="modal_conversa_funil" size="xl" hide-footer title="Conversa">
            <div class="d-block text-center">
                <div class="row" v-if="clienteAtendimento">
                    <div class="col-sm">
                        <div class="container" id="cartao_cliente">
                            <div class="row">
                                <div class="col-sm" style="max-width: 20%;">
                                    <img src="/images/user.png" style="width:45px; position:absolute; top:0; left:0">
                                </div>
                                <div class="col col-lg-12">
                                    <div class="row">
                                        <div class="col-sm col_titulo_cartao_cliente">
                                            <span class="titulo_cartao_cliente"> Nome Fantasia:</span>
                                            <span class="dado_cartao_cliente">{{ clienteAtendimento? clienteAtendimento.NomeFantasia : '' }}</span>

                                        </div>
                                        <div class="col-sm col_titulo_cartao_cliente">
                                            <span class="titulo_cartao_cliente"> Razão Social:</span>
                                            <span class="dado_cartao_cliente">{{ clienteAtendimento? clienteAtendimento.RazaoSocial : '' }}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm col_titulo_cartao_cliente">
                                            <span class="titulo_cartao_cliente">Cpf/Cnpj:</span>
                                            <span class="dado_cartao_cliente">{{ clienteAtendimento? clienteAtendimento.CnpjCpf : '' }}</span>
                                        </div>
                                        <div class="col-sm col_titulo_cartao_cliente">
                                            <span class="titulo_cartao_cliente">Telefone:</span>
                                            <span class="dado_cartao_cliente">  {{ clienteAtendimento? clienteAtendimento.Telefone : '' }}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm col_titulo_cartao_cliente">
                                            <span class="titulo_cartao_cliente ">Email:</span>
                                            <span class="dado_cartao_cliente"> {{ clienteAtendimento? clienteAtendimento.Email : '' }}</span>
                                        </div>
                                        <div class="col-sm">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <!--<div id="btn_editar_cliente_funil">Editar Cliente</div>-->
                    </div>
                </div>
                <span id="lstConversa">
                    <span id="modal_atendimento">Atendimento {{ProcoloConversa}}</span>
                </span>
            </div>
        </b-modal>
        <Breadcrumb titulo="Kanban" :items="itensBreadcrumb" />
        <div class="container-fluid py-4">
            <div class="row">
                <div class="col-12" id="scrolar">

                    <div id="menu_funil"><span id="btn_grafico_funil" v-on:click="visualizar_graficos"><i class="fas fa-chart-pie"></i>  Gráfico</span></div>
                    <div id="caixa_kanban">
                        <div id="segura_funil">
                            <!--Criar Funil-->
                            <div class="flex justify-center">
                                <div id="kanba_one">
                                    <div v-for="column in columns"
                                         :key="column.IdCabecalho"
                                         :id="column.IdCabecalho"
                                         class="bg-gray-100 rounded-lg p-3 column-width rounded mr-4 insert_scroll"
                                         v-bind:style="{ 'background-color': column.Cor  }"
                                    >
                                        <div class="card-task-details">
                                            <span class="titulo_coluna_funil">{{column.Coluna}}</span>
                                            <span class="qnt_itens_funil">{{column.Consulta.length}}</span>
                                        </div>

                                        <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
                                        <draggable 
                                            :list="column.Consulta"
                                            :animation="200"
                                            :move="checkMove"
                                            @change="mudarColuna"
                                            ghost-class="ghost-card"
                                            group="atendimentos"
                                            class="column-drag"
                                        >
                                            <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                                            <!--<task-card v-for="(task) in ordernar(column.Consulta)"-->
                                            <task-card 
                                                v-for="(task) in column.Consulta"
                                                :key="task.IdAtendimentoTabulacao"
                                                :task="task"
                                                @abrirConversa="abrirConversa"
                                            >
                                            </task-card>
                                        </draggable>

                                    </div>
                                </div>
                            </div>
                            <!--Final Funil-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import axios from 'axios'
    import draggable from "vuedraggable";

    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import GraficoRosca from '@/components/graficos/GraficoRosca.vue'
    import TaskCard from "@/components/TaskCard.vue";

    export default {
        name: 'ConsultaMensagensPage',
        components: {
            Breadcrumb, draggable, TaskCard, GraficoRosca
        },
        data: function () {
            return {
                columns: [
                    {
                        Consulta: [{ Atendimento: '', ClienteNome: '', Id: '', IdCabecalho: '', Inclusao: '', Protocolo: '', UltimaAlteracao: '' }]
                    },
                ],
                itensBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-search',
                        texto: 'Consultas',
                        link: '/consultas'
                    },
                    {
                        id: '2',
                        classe: 'fas fa-search',
                        texto: 'Consultas mensagens',
                        link: ''
                    },
                ],
                ProcoloConversa: '',
                IdRespostaTab: '',
                IdAtendimentoTab: '',
                clienteAtendimento: [],
                dadosGraficoAtendimento: { texto: [""], valor: [0] },
                dadosGraficoTabulacao: { texto: [""], valor: [0] },
                porcentagemTabulados: { porcentagem: 0, total: 0, total_tabulados: 0, },
                valores_tab: null,
                lista_coluna_modificada: null,
                card_modificado: null
            }
        },
        computed: {
            ...mapGetters({
                enumOrigem: 'atendimento/enumOrigem',
            }),
        },

        created() {
            this.carregarFunil()
        },

        methods: {
            ...mapActions({
                buscaEnumsAtendimentos: 'atendimento/buscaEnums',
            }),
            mudarColuna: function (evt) {
                this.IdAtendimentoTab = evt.added?.element.IdAtendimentoTabulacao;
                var dataAgora = new Date();
                if (this.IdAtendimentoTab && this.IdRespostaTab) {
                    this.card_modificado.DataOrdem = dataAgora.toISOString();
                    this.card_modificado.Atendimento.UltimaAlteracao = dataAgora.toLocaleDateString();
                    this.ordernarColuna();
                    const dados = {
                        AtendimentoTabulacaoId: this.IdAtendimentoTab,
                        TabulcaoItemId: this.IdRespostaTab
                    }
                    axios.post('/api/tabulacao/modificaKanban/',dados)
                        .then((response) => { }, (error) => { console.log(error); });
                }
            },
            ordernarColuna: function () {
                this.lista_coluna_modificada.sort(function (a, b) { if (a.DataOrdem < b.DataOrdem) { return 1 } if (a.DataOrdem > b.DataOrdem) { return -1 } return 0 })
            },
            carregarFunil: function () {
                $("#caixa_carregamento").show();
                axios.get('/api/tabulacao/buscafunil')
                    .then((response) => {
                        this.columns = response.data
                       
                        $("#caixa_carregamento").hide()
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            calculaProcentagem(v1, soma) {
                let somaItens = soma.reduce((a, b) => a + b, 0);
                //v1 = menor, v2 maior ou soma de todos
                return ((v1 * 100) / somaItens).toFixed(2)
            },
            visualizar_graficos: function () {
                
                this.dadosGraficoAtendimento = { texto: ["atendimentos", "tabulados"], valor: [this.columns[0].qnt_atendimentos, this.columns[0].qnt_tabulados] };

                let textosGTab = this.columns.map(c => c.Coluna);
                let itensGTab = this.columns.map(c => c.Consulta.length);
                this.dadosGraficoTabulacao = { texto: textosGTab, valor: itensGTab }
                this.valores_tab = this.columns.map(c => {
                    return { nome: c.Coluna, qnt: c.Consulta.length }
                });
                this.valores_tab = this.ordernar(this.valores_tab);
                let p = 0;
                if (this.columns[0].qnt_tabulados > this.columns[0].qnt_atendimentos) {
                    p = this.columns[0].qnt_tabulados - this.columns[0].qnt_atendimentos;
                } else {
                    p = this.columns[0].qnt_atendimentos - this.columns[0].qnt_tabulados;
                }
                this.porcentagemTabulados = {
                    porcentagem_c: ((this.columns[0].qnt_tabulados * 100) / this.columns[0].qnt_atendimentos).toFixed(2),
                    porcentagem_s: ((p * 100) / this.columns[0].qnt_atendimentos).toFixed(2),
                    total: this.columns[0].qnt_atendimentos,
                    total_tabulados: parseInt(this.columns[0].qnt_tabulados),
                }
                this.$refs['modal_graficos_funil'].show()
            },
            ordernar: function (items) {
                if (items != null) {
                    items.sort(function (a, b) {
                        if (a.qnt < b.qnt) {
                            return 1;
                        }
                        if (a.qnt > b.qnt) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                    });
                }
                return items;
            },
            abrirConversa: function (id, pc) {
                this.ProcoloConversa = pc;
                $("#caixa_carregamento").show();
                axios.get('/api/atendimento/GetConversaPorAtendimento/' + id)
                    .then((response) => {
                        this.clienteAtendimento = response.data.Cliente
                        var conversaJquery = response.data.Conversa;
                        $("#caixa_carregamento").hide();
                        for (var i in conversaJquery.sort((a, b) => (a.Data > b.Data) ? 1 : ((b.Data > a.Data) ? -1 : 0))) {
                            if (conversaJquery[i].Minha == true) {
                                $("#lstConversa").append(
                                    "<div class='container_conversa'>" +
                                    "<div class='container_caixa_conversa_minha'>" +
                                    "<div class='container caixa_conversa_minha'>" +
                                    "<div class='row'><div class='col-sm'><span class='texto_min'>" + conversaJquery[i].Origem + "</span></div><div class='col-sm'></div><div class='col-sm'><span class='texto_min topo_cor' style='background-color:" + conversaJquery[i].Cor + "'>" + conversaJquery[i].Tipo + "</span></div></div>" +
                                    "<div class='row'><div class='col-sm'><i class='fas fa-headset' style='display: table;'></i><span class='ajuste_msg'> " + conversaJquery[i].Mensagem + "</span></div></div>" +
                                    "<div class='row'><div class='col-sm'><span class='texto_min'>" + conversaJquery[i].Usuario + "</span></div><div class='col-sm'></div><div class='col-sm'><span class='texto_min footer_data'>" + conversaJquery[i].Data + "</span></div></div>" +
                                    "</div></div></div>");
                            } else {
                                $("#lstConversa").append(
                                    "<div class='container_conversa'>" +
                                    "<div class='container_caixa_conversa'>" +
                                    "<div class='container caixa_conversa'>" +
                                    "<div class='row'><div class='col-sm'><span class='texto_min'>" + conversaJquery[i].Origem + "</span></div><div class='col-sm'></div><div class='col-sm'><span class='texto_min topo_cor' style='background-color:" + conversaJquery[i].Cor + "'>" + conversaJquery[i].Tipo + "</span></div></div>" +
                                    "<div class='row'><div class='col-sm'><span class='ajuste_msg'> <i class='fas fa-user' style='display: block'></i> " + conversaJquery[i].Mensagem + "</span></div></div>" +
                                    "<div class='row'><div class='col-sm'><span class='texto_min'>" + conversaJquery[i].Usuario + "</span></div><div class='col-sm'></div><div class='col-sm'><span class='texto_min footer_data'>" + conversaJquery[i].Data + "</span></div></div>" +
                                    "</div></div></div>");
                            }
                            $(".container_conversa").css({ "display": "table", "width": "100%" })
                            $(".container_caixa_conversa_minha").css({ "display": "table", "width": "50%", "float": "right", "margin": "2%" })
                            $(".container_caixa_conversa").css({ "display": "table", "width": "50%" })
                            $(".caixa_conversa").css({
                                "display": "table", "min-width": "202px", "min-height": "50px", "background-color": "#e4e4e4", "margin": "10px", "border-radius": "10px 10px 10px 0px", "padding": "2%"
                            });
                            $(".caixa_conversa_minha").css({
                                "display": "table", "min-width": "202px", "min-height": "50px", "background-color": "#c6c6c6", "margin": "10px", "border-radius": "10px 10px 0px 10px", "padding": "2%"
                            });
                            $(".ajuste_msg").css({ "padding": "2%", "display": "block", "text-align": "left" });
                            $(".footer_data").css({ "float": "right" });
                            $(".texto_min").css({ "font-size": "10px", "font-weight": "bold" });
                            $(".topo_cor").css({ "padding": "1%", "border-radius": "6px", "width": "30%", "display": "table", "text-align": "center", "float": "right" });
                            
                        }
                        $("#caixa_carregamento").hide()
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
                this.$refs['modal_conversa_funil'].show()
            },
            changed: function (v) {
               
            },
            checkMove: function (evt, coluna) {
                this.card_modificado = evt.draggedContext.element;

                this.lista_coluna_modificada = evt.relatedContext.list;
                this.IdRespostaTab = evt.to?.parentElement.id;
            },
        }
    }
</script>

<style scoped>
    .column-width {
        min-width: 320px;
        width: 200px;
        display: inline-table;
    }
    /* Unfortunately @apply cannot be setup in codesandbox,
    but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
    .ghost-card {
        opacity: 0.5;
        background: #F7FAFC;
        border: 1px solid #4299e1;
    }

    .column-drag {
        height: calc(100% - 25px);
    }

    .titulo_coluna_funil {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
    }

    #segura_funil {
        width: 3500px;
        display: block;
    }

    #caixa_kanban {
        display: block;
        width: auto;
        overflow-x: auto;
    }
    /*#scrolar {
        overflow: scroll;*/
    /* width: 100px; */
    /*display: block;
        height: 720px;
    }*/

    .qnt_itens_funil {
        float: right;
        display: block;
        font-weight: bold
    }

    #lstConversa {
       
        height: 270px;
        overflow-y: scroll;
        display: block;
    }

    #modal_atendimento {
        display: block;
        float: left;
    }

    #cartao_cliente {
        background-color: #fdf9dd;
        padding: 25px;
        border: 1px solid #e0d376;
        width: 700px;
    }

    span.titulo_cartao_cliente {
        font-weight: bold;
        font-size: 13px;
    }

    .col_titulo_cartao_cliente {
        text-align: left;
        margin-left: 40px;
    }

    span.dado_cartao_cliente {
        font-size: 12px;
        margin: 10px;
    }

    #menu_funil {
        display: table;
        width: 100%;
        background: aliceblue;
        position: relative;
    }

    #btn_grafico_funil {
        cursor: pointer;
        color: #3f3da8;
        padding: 9px;
    }

        #btn_grafico_funil:hover {
            color: #aaa9ea;
        }

    #caixa_chart_atendimentos-chart {
        width: 200px;
        height: 200px;
        display: table
    }

   /* canvas#doughnut-chart {
        width: 180px !important;
        height: 180px !important;
        margin: 0px auto
    }*/

    .informacao_grafico {
        float: left;
        display: table;
        width: 100%;
        text-align: left;
        font-family: Verdana;
        padding: 3px;
    }
    #kanba_one {
        height: 650px
    }

    .card-task-details {
        height: 25px;
    }

    @media (max-width:1600px) {
        .insert_scroll {
            overflow-y: scroll;
            display: inline-block;
            height: 470px !important;
        }
        #kanba_one {
            height: 480px
        }
    }

    .insert_scroll {
        overflow-y: scroll;
        display: inline-block;
        height: 100%;
    }

    #btn_editar_cliente_funil {
        background-color: #5196d2;
        display: table;
        padding: 10px;
        color: #FFF;
        cursor: pointer;
    }

        #btn_editar_cliente_funil:hover {
            background-color: #98c1e4;
        }
</style>